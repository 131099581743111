.footer_logo {
  width: 100px;
}

footer {
  background-color: #0b0d17;
  color: #d9dbe1;
}

.download-app-img {
  height: 35px;
}

.social-media-icons {
  height: 20px;
  width: 20px;
  position: absolute;
  
}

.social-media-circle {
  background: #fff;
  height: 35px;
  width: 35px;
  opacity: 0.2;
  border-radius:50%;
}


footer li{
  line-height:2.5 !important;
}

.footer-title{
  font-weight: 600;
  font-size: 20px;
}
.address{
  line-height: 1.6 !important;
}


footer li,li p {
color:#D9DBE1;
font-size:14px !important;
font-weight: 400;
line-height: 2;
}

.policy a{
  color: #D9DBE1;
  opacity: 0.7;
}
.storyset{
  font-size: 14px;
  color: #D9DBE1;
opacity: 0.5;
}

.policy{
  font-size: 14px;
  /* margin-right: 3rem; */
}
.swadeshi-logo{
  max-height: 80px;
}

.fs-17{
  font-size: 20px;
}

.details{
  line-height: 1.7 !important;
}

.swadeshi-div{
  width: 50%;
}

.blud-info{
  width: 50%;
}

@media only screen and (max-width: 1850px) {

  
  .blud-info{
    width: 60%;
  }
  .swadeshi-div{
    width: 40%;
  }

}

@media only screen and (max-width: 1580px) {

  
  .blud-info{
    width: 70%;
  }
  .swadeshi-div{
    width: 30%;
  }

}


@media only screen and (max-width: 1580px) {

  
  .blud-info{
    width: 80%;
  }
  .swadeshi-div{
    width: 20%;
  }

}

@media only screen and (max-width: 1200px) {
  .policy{
    margin-right: 25px;
    margin-right: 15px;
    margin-left: unset;
    margin-bottom: unset;
  }

.copyright-div{
  justify-content: unset !important;
}

.get-app{
  display: flex;
  align-items: center;
}

.get-app li{
  margin-bottom: unset !important;
  margin-right: 15px;
}

footer li{
  line-height: 2 !important;
}
}

@media only screen and (max-width: 568px) {
  .policy{
    margin-right: 15px;
    margin-left: unset;

    margin-bottom: unset;

  }
.blud-info{
  width: 70%;
}

}

@media   only screen and (max-width: 992px){
  .get-app{
    display: block;
    /* align-items: center; */
  }

  .get-app li{
    margin-bottom: 15px !important;
    margin-right: 15px;
  }
  
}