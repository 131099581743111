.faq li{
    /* list-style-type: none; */
    line-height:1.7
}

.faq-img{
    width: 100%;
    height: 100%;
}


