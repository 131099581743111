* {
  font-family: "Source Sans Pro", sans-serif !important;
  box-sizing: border-box;
}

.bd_logo {
  height: 50px;
}

.f-bebas {
  font-family: "Bebas Neue", cursive !important;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app-padding {
  padding-left: 120px;
  padding-right: 120px;
}

.fw-600{
font-weight: 600;
}
.app-margin {
  margin-left: 120px;
  margin-right: 120px;
}

.navbar {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.bd_active {
  width: 95%;
  margin: auto;
  height: 4px;
  background-color: #af0c0c;
  border-radius: 100px;
}

.nav-link {
  color: #000000;
}

.nav-item {
  margin: 0 10px 0 10px;
}

.active {
  color: #af0c0c;
}

a.nav-link:hover {
  color: #af0c0c;
}

/* .bd_homepage {
  height: calc(100vh - 100px);
  border: 1px solid;
} */

.google,
.apple {
  width: 130px;
  /* height: 40px; */
}

.angle{
  max-height: 250px;
}
@font-face {
  font-family: 'SFRegular';
  font-weight: 700 ;
  src: url("../font/SFProText-Regular.ttf");
}


.fill-gap-counts{
  font-weight: 700;
  font-size: 52px;
  font-family:"SFRegular" !important;

}

.fill-gap p{
  /* font-weight: 600; */
  margin-bottom: 25px;
}

.bd_counts {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 3rem;
}

.bd_counts > div {
  padding: 20px 20px 20px 30px;
}

.bd_counts img {
  height: 80px;
  margin-right: 15px;
}

.slick-slider {
  height: 600px;
}

.MuiPaper-elevation24{
  box-shadow: unset !important;
}

/* .MuiPaper-root {
background-color: transparent !important;
} */

.MuiTabs-indicator{
  display: none;
}

/* .slick-dots {
bottom: 0;

} */
.MuiTypography-h6 {

font-weight: 600;
}
.swal2-icon.swal2-success{
  border-color: #fff;
  color: #fff;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #fff;

}
.swal2-icon.swal2-success [class^=swal2-success-line]{
  background-color: #fff;

}

.map{
  border-radius: 18px;
}

.swal2-html-container{
  padding: 1.6em;
border-radius: 0 0 8px 8px;

}

.swal2-popup{
  padding:0;
box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 8px 8px 8px 8px;
}
.swal2-html-container{
  background-color: #fff;
}

.swal2-title{
  color: #fff;
  font-size:16px;
  text-transform:uppercase;
  padding: 10px 0 25px 0;
}
.success-pop-up{
  height: 300px;
  width: 300px;
  background-color: #cb4747;

}
.bd_counts > div:nth-child(2),
.bd_counts > div:first-child {
  border-right: 2px solid #eeeff2;
  height: 60%;
}

.blud_nearby {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}


.bd_count_nearby {
  /* padding:1rem */
}

.bd_hr_border {
  width: 85%;
  border-top: 2px solid #eeeff2;
  margin-left: 5%;
}

.bd_vr_border {
  height: 90px;
  background-color: #eeeff2;
  width: 2px;
  /* margin: 15px; */
  margin: 15px auto 15px auto;
}

.bd_get_the_app {
  background-color: #cb4747;
  padding: 4rem 4rem 0 4rem;
  border-radius: 8px;
  color: #fff;
  max-height: 450px;
}

.bd_get_the_app .sub-title {
  font-size: 40px;
}

.bd_get_the_app_right {
  background-image: url(../images/Pattern.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 386px;
}

.bd_get_the_app_right img {
  height: 400px;
  right: 0;
  position: absolute;
  bottom: 0;
}

.bd_eligibility-div {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 1rem;
  width: 400px;
  height: 450px;
}

.MuiDrawer-paper {
  width: 500px !important;
  height: auto !important;
  top: unset;
  bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.file-upload {
  border: 1px solid #af0c0c;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  color: #af0c0c;
  padding: 5px;
}

.main-header-title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.main-header-sub-title {
  font-size: 38px;
  font-weight: 700;
}

.who-img {
  /* width: 520px !important; */
  /* max-height: 420px !important; */

}

.bd-donations-bu-title{
  font-size: 18px;
  font-weight: 500;
}

.who-title {
  font-size: 24px;
  color: #222222;
  font-weight: 600;
}

.pop-up-blood{
  max-height: 750px;
}

.who-text-pl {
  padding-left: 5rem;
}

/* .mission-save-life li {
  line-height: 1.8;
  font-size: 18px;
} */

.error-border{
  border-color: red;
}

.error{
  color: red;

}

.app-sub-margin {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.btn-primary,
.btn-primary:hover,.btn-outline-primary:hover {
  background-color: #af0c0c;
  border-color: #af0c0c;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
  background-color: #af0c0c;
  border-color: #af0c0c;
  
}

.btn-primary.focus, .btn-primary:focus{  box-shadow: unset !important;
}

.btn-outline-primary{
  border-color: #af0c0c;
  color: #af0c0c;

}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: #af0c0c;
  border-color: #af0c0c; 
}
.primary-color {
  color: #af0c0c;
}

.bd_counts_label {
  color: #4f5665;
  font-size: 22px;
}

p,li {
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
}

.bd_counts_value {
  font-size: 26px;
  font-weight: 700;
}

.bd-nearby-group,
.bd-nearby-count {
  font-size: 38px;
}

.blud_nearby .row {
  padding: 10px;
}

.btn-outline-danger{
  color: #af0c0c;
  border-color: #af0c0c;

}

.bd-nearby-group {
  font-weight: 700;
  color: #af0c0c;
}

.bd-nearby-count {
  font-weight: 600;
  color: #0b132a;
}

.bg-transparent {
  background-color: transparent !important;
}

.btn{
  border-radius: 8px;

}

.blood-type-img {
  width: 80%;
}

.btn-blue{
  background-color: #2A6BF4;
  color: White;
}

.bd_eligibility-div li {
  list-style: none;
  line-height: 2;
  font-weight: 400;
}
.bd_eligibility-div ul {
  padding: unset;
}
.bd_eligibility-div .sub-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.bd_drop {
  height: 100px;
  margin: 30px 0;
}
.MuiTabs-flexContainer {
  justify-content: space-between !important;
}
.MuiTabs-root {
  background: rgba(151, 151, 151, 0.1);
  border-radius: 8px;
}

.MuiTab-textColorPrimary {
  color: #3b4559 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  background: #ffffff;
  border-radius: 8px;
  color: #af0c0c !important;
  margin: 5px;
  font-weight: 600;
}
 .MuiTab-textColorPrimary{
   min-height: 20px !important;
 }

.faq-question {
  font-weight: 700 !important;
  font-size: 18px !important;
}

.faq-ans {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.MuiPaper-elevation1 {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);

  box-shadow: unset !important;
}
.MuiAccordion-root.Mui-expanded:before {
  opacity: unset;
}

.PrivateTabIndicator-colorPrimary-2 {
  background-color: transparent !important;
}

.MuiAccordionSummary-content.Mui-expanded,
.MuiAccordion-root.Mui-expanded {
  margin: unset !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
}

.bd-contact-us {
  min-height: 650px;
}

.contact-us-para {
  font-weight: 600;
}

.bd-captcha > div {
  display: flex;
}

.clear-btn{
  width: 120px;
}

.bd-captcha canvas {
  margin-right: 10px;
}


.PrivateSwipeArea-anchorRight-3 {
  width: unset !important;
}

.PrivateTabIndicator-colorPrimary-7 {
  background-color: transparent !important;
}

.colorChange {
  background-color: white;
}

.primary-black{
  color: #222222;
}
.request-blood {
  font-weight: 600;
  font-size: 18px;
}

.collapse.show {
  background-color: white;
  padding: 10px;
}

.collapse.show .bd_active {
  width: 100%;
}

.app-top-margin {
  margin-top: 100px !important;
}

.color-white,
.color-white:hover {
  color: white !important;
  opacity: unset !important;
}

.justify-space-evenly{
  justify-content: space-evenly;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.progress-spinner{
  height:20px !important;
  width:20px !important;
  color:#fff !important;

}
/* media query starts */

@media only screen and (max-width: 1600px) {
  .app-padding {
    padding-right: 70px;
    padding-left: 70px;
  }

  .app-margin {
    margin-right: 70px;
    margin-left: 70px;

  }

  .bd_eligibility-div {
    width: auto;
  }



  .angle{
    height: auto;
  }
  .fill-gap-counts{
    font-size: 42px;

  }
}
@media only screen and (max-width: 1500px) {

.blood-type-img{
  width: 100%;
}
/* .slick-slider {
  height: 550px;
} */

}

@media only screen and (max-width: 1300px) {
  .bd_get_the_app  {
    padding:2rem 2rem 0 2rem;
    max-height: 418px;
  }
  .app-padding {
    padding-right: 50px;
    padding-left: 50px;
  }

  .app-margin {
    margin-right: 50px;
    margin-left: 50px;

  }

  /* .company-logo-main-div{
    align-self: center;
  }  */

  /* .slick-slider {
    max-height: 450px !important;
  } */


}

@media only screen and (max-width: 600px){
  .bd_counts{
    grid-template-columns: 1fr;
  }
  .bd_counts > div:nth-child(2), .bd_counts > div:first-child{
    border-right: unset;
    border-bottom: 2px solid #eeeff2;
  height: unset;
  }

  .bd_counts > div{
    margin:0 10px 0 10px;
    justify-content: flex-start;
  }

  /* .bd_counts > div:nth-child(3){
    margin-left: 70px;

  } */

  .main-header-title{
    font-size: 32px;
  }
  
 

}


@media only screen and (max-width: 992px) {
  .map{
    margin-top: 2rem;
  }

  .who .img-div{
    text-align:center;
    margin-bottom:15px;

  }

  .main-header-title{
    /* text-align:center; */
    margin-bottom: 15px;
    font-size: 32px;
  }
  .who-title{
    text-align: right !important;
    margin-right: 40px;
  }

  .bd-nearby-group, .bd-nearby-count{
    font-size: 32px;
    text-align: center;
  }

  .bd_get_the_app{
max-height: 480px ;

  }

  p, li{
    line-height: 1.4;
  }

  .who-img-div{
   /* text-align: center; */
   margin-bottom: 2rem;
   }

   .app-sub-margin{
     margin-top: 1rem;
     margin-bottom: 1rem;

   }

   
  /* .slick-slider {
    max-height: 400px !important;
  } */

   /* .app-top-margin{
     margin-top: 50px !important;
   } */
   .faq-img{

    height: 85%;
  }
  .bd_counts{
    margin-top: 2rem;
  }
  .bd_counts img{
    height: 50px;
  }
  
  .bd_counts > div{
    padding:5px 5px 5px 15px;
  }

  .bd_counts_value{
    font-size: 20px;
  }

  .bd_counts_label{
    font-size: 18px;
  }

  .bd_vr_border{
    height: 60px;
  }

  .bd-nearby-group, .bd-nearby-count{
    font-size: 24px;
  }
}

.dialog-btns{
  justify-content: space-evenly !important;
}


@media only screen and (max-width: 918px) {

  .bd_get_the_app_right img {

    top: 12.5%;
  }
}

@media only screen and (max-width: 768px) {
  .bd_get_the_app{

  height: unset !important;
  max-height: unset !important;
  }

  .bd_get_the_app_right img {

    top: 0;

  }
  .bd_get_the_app_right{
    height: 350px;
  }
  .bd_get_the_app_right img {
    left: 50%;
    transform: translateX(-50%);
height: 100%;  }
.app-padding {
  padding-right: 20px;
  padding-left: 20px;
}

.app-margin {
  margin-right: 20px;
  margin-left: 20px;
}


.who-img-div{
  text-align: center;
  margin-bottom: 1rem;
}

.blud-app{

height: 300px;}
.company-logo-main-div{
text-align: center !important;
}
.bd_vr_border{
  height: 60px;
}
.slick-slider {
  max-height: 350px !important;
}



}


@media only screen and (max-width: 576px) {
  .google,
  .apple {
    width: 100px;
    /* height: 40px; */
  }
  

  .map{
    height: 300px;
  }

  .company-logo{
   width: 100%;
   height: 300px; 
   max-height: 300px;
  }

  .footer-title{
    font-size: 18px;
  }
}

#reload_href{
  color: #5B5B5B !important;
  font-size: 12px;
}


@media only screen and (max-width: 440px) {
  li.policy  a{
 font-size: 11px;
 }

 .company-logo{
  height: 250px; 
 }

}
/* media query ends */

.blud-type-dialog .MuiPaper-root{

  border-radius: 8px !important;
  background-color: transparent;
}


@media only screen and (max-width: 650px) {

  .blud_nearby{
    grid-template-columns: 1fr;
  }

  .bd_vr_border {
height: unset;
  }

}


@media only screen and (max-width: 500px) {

.main-header-title{
  font-size: 28px;
}

}

@media only screen and (max-width: 400px) {

  .main-header-title{
    font-size: 20px;
  }

  .main-heading{
    font-size: 26px !important;

  }
  .fill-gap-counts{
    font-size: 30px !important;

  }
  
  }



  .app-qr-code > div{
    line-height: 2;
    color: #AF0C0C;
    font-weight: 600;
    font-size: 20px;
  }


  #alert-dialog-title h2{
    font-weight: 600;
    font-size: 24px;
  }

  .qr-code .MuiDialog-paper{
padding: 20px 35px;
border-radius: 12px;
  }

  .btn{
    min-width: 100px
  }