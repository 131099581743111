
.company-logo {
    max-height: 250px;
  }
  
  .company-logo-div {
    /* width: 300px; */
    /* text-align: center !important; */
  }

.bd-app-title{
    font-size:35px;
    font-weight:600;
}


.about-us-img{
    max-height: 470px;
width: 520px;}



.blud-app{
  max-height: 450px;
}