.stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.step-title {
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 700;
}

.step-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
}

.step-bar-left,
.step-bar-right {
  position: absolute;
  top: 25%;
  border: 1px dashed #af0c0c;
  border-width: 1px 0;
}
.step-bar-left {
  width: calc(100% - 40%);
  left: 32%;
  z-index: -1;
  margin: 0 100px;
}
.step-bar-right {
  width: 0;
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
  transition: width 500ms ease-in-out;
}

.grid{
  display:grid;
  grid-template-columns: repeat(6,1fr);
}

.step {
  /* display: table-cell; */
  position: relative;
  padding: 1.5rem;
  z-index: 2;
  /* min-width: 16.6666666667% ; */
  /* width: 300px !important; */

  /* border: 1px solid ; */
}


.step:last-child .step-bar-left {
  display: none;
}

.step-img {
  border: 1px solid #af0c0c;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: relative;
  padding: 10px;
  z-index: 2;
  margin-bottom: 1rem;
}
.bd-donation-title {
  font-size: 48px;
  font-weight: 600;
}

.step-img img {
  height: 40px;
  width: 40px;
}

.steps-padding {
  padding: 0 120px;
}

@media only screen and (max-width: 1670px) {
  .steps-padding {
    padding: unset;
  }

  .step-img img {
    width: 40px;
    height: 40px;
  }

  .step-bar-left {
    top: calc(2rem + 25px);
    width: calc(100% - 50%);
    left: 28%;
  }
}

@media only screen and (max-width: 1460px) {
  .step-bar-left {
    top: calc(2rem + 25px);
    width: calc(100% - 55%);
    left: 25%;
  }
}

@media only screen and (max-width: 1260px) {
  .step-bar-left {
    left: 21%;
  }
}

@media only screen and (max-width: 1155px) {
  .step-bar-left {
    left: 22%;
    width: calc(100% - 65%);
  }
}

@media only screen and (max-width: 1070px) {
  .step-bar-left {
    left: 21%;
  }

  .step-img {
    width: 65px;
    height: 65px;
  }

  .step-img img {
    height: 35px;
    width: 35px;
  }
}
@media only screen and (max-width: 990px) {
  /* .step-img img {
    width: 40px;
    height: 40px;
  } */

  .step-bar-left {
    left: 8%;
    top: calc(2rem + 22px);
  }
  div.grid > div:nth-child(4) > div.step-bar-left{
    left :13% !important; 
  }
  div.grid > div:nth-child(5) > div.step-bar-left{
    left :19% !important; 
  }
}

@media only screen and (max-width: 930px) {
  .step-bar-left {
    left: 6%;
    top: calc(2rem + 18px);
    width:50px;
  }

  .step {
    padding: 1rem;
  }
}

@media only screen and (max-width: 815px) {
  .step-img img {
    width: 40px;
    height: 40px;
  }

  .step-bar-left {
    left: 5%;
    top: calc(2rem + 9px);
  }

  .step-title {
    font-size: 16px;
  }

  .step-content {
    font-size: 14px;
  }
}

.registration-process {
  width: 350px;
  margin: auto;
}
.donation-process {
  align-items: unset !important;
}

.vertical-bar-steps {
  border-left: 1px dashed #af0c0c !important;
  height: 50px !important;
  background-color: unset !important;
  width: 0;
  margin: auto;
}

.title {
  padding-top: unset !important;
}

.registration-process-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}


.verticle-bar{
  border-left:2px dashed #af0c0c !important;
  height: 25px !important;
  width: 2px;
  margin:10px auto 10px auto;
}


.step-grid{
  display: grid;
  grid-template-columns: 65px 1fr;
  /* align-items: center; */
}

.vertical-list{
  max-width: 350px;
  margin: auto;
}