


.slider-1-img,.slider-2-img{
  height:500px;
  width: 330px;
  /* position:absolute; */
  /* bottom: -7%; */
  /* clip: rect(0px,260px,450px,0px); */

}


.slider2-img {
  height: 500px;
  /* width: 350px; */
}

.color-black ,.color-black:hover{
  color:#18191F
}

.slider3-img,.slider6-img  {
  /* width: 70%; */
  max-height: 500px;
}


.slider4-img {
  width: 100%;
  max-height: 100%;
}

.slider5-img{
  max-height: 550px !important;

}


.slider-slogan {
  font-family: "Homenaje", sans-serif;
  padding-left: 2rem;
}

.slick-active li button{
  color: #AF0C0C;
}

.slick-dots li{
  height: 10px;
  width: 10px;

}



.slick-dots li button:before{
  height: 10px;
  width: 10px;
  border: 2px solid #979797;
  color: transparent ;
  border-radius: 100%;
}
.slick-dots li.slick-active button:before {
  color: transparent ;
  background-color: #AF0C0C;
  border-radius: 100%;
}
.slider-heading {
  font-size: 52px;
  color: #D32F2F !important;
  font-family: 'Bebas Neue', cursive !important;
  margin-bottom: 15px;
}

.slider-subheading {
  font-size: 38px;
  font-family: 'Bebas Neue', cursive !important;
  font-weight:400;
}

.slider-subheading1{
  color:#D32F2F;
}
.main-sub-heading {
  color: #AF0C0C;
}

.main-heading {
  font-size: 59px;
  font-weight: 700;
  line-height: 1.2;
}

.main-heading:nth-child(2){
  margin-bottom: 15px;
}
.auth div{
    padding:20px;
    font-weight: 600;
}

.slider_row{
    /* margin-top:5%; */
    align-items: center !important;
  min-height: 500px;

}

.slider1-grid{
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  height: 550px;
}




@media only screen and (max-width: 568px) {
  .main-heading {
    font-size: 32px !important;
  }

  .slider-heading{
    font-size: 32px !important;

  }
  .slider-subheading {
    font-size: 34px;

  }
  
  }

@media only screen and (max-width: 1600px) {
  .main-heading {
    font-size: 48px;
  }

  .slider-heading{
    font-size: 48px;

  }
  .slider-subheading {
    font-size: 34px;

  }
  

  



}



@media only screen and (max-width: 1290px) {
  .slider1-img{
    height: auto;
  }
  
  
  .slider2-grid {
    grid-template-columns: 60% 40% !important;
}
.slider3-sec2,.slider5-sec3,.slider6-sec3{
  display: none;
}
  .slider3-img{
    width:100% !important;
    /* height:450px; */
  }

  .slider-subheading{
    font-size:36px !important
  }

  .slider-heading{
    font-size: 40px !important;
  }
  .main-heading{
    font-size:46px ;

  }

.slider-1-img{
  height:450px;
  width: 300px;
}

 p{
  font-size: 15px;
}

.slider4-sec3 ,.slider2-img-div{
  display: none;
}
/* .slider2-sec3,.slider3-sec2,.slider6-sec3,.slider5-sec3{
  display: none;
} */


.slider4-grid{
  grid-template-columns: 60% 40% !important;
}


}




@media only screen and (max-width: 1350px) {


 

  .slider-subheading{
    font-size: 34px !important;
  }

}

@media only screen and (max-width: 768px) {

  .slider6-img{
    height: auto;
  }
  /* .slider5-sec3{
    display: none;
  } */
}
@media only screen and (max-width: 1230px) {



  .slider-1-img{
    height:400px;
    width: 300px;
    margin-right:50px;
  }
/* 
  .slider2-grid {
    grid-template-columns: 45% 25%  33% !important;
} */
}


.slider4-grid{
  display: grid;
  grid-template-columns:35% 35% 30%;
  align-items: center;

height: 550px;


}



/* .slider4-img{
  position: absolute;
  height: 450px;
  bottom: -200px;
  width: 600px;
  right: 100px;
  max-width: unset;
} */


.slider2-grid{
  display: grid;
  grid-template-columns: 40% 30%  30%;
  align-items: center;
height: 550px;

  
}



@media only screen and (max-width: 1300px) {

  .slider2-grid{
    grid-template-columns: 40% 30%  30%;
  } 


}


@media only screen and (max-width: 840px) {
  .slider-subheading{
    font-size:26px !important
  }

  .slider-heading{
    font-size: 38px !important;
  }
  .main-heading{
    font-size:39px ;

  }
}

@media only screen and (max-width: 760px) {
  .slider-slogan,.slider1-img-div{
    display: none;
  } 

  .slider2-grid,.slider1-grid,.slider4-grid{
grid-template-columns: 1fr !important;
width: 80%;
margin: auto;
  } 


  .slider4-grid,.slider1-grid {
    height: 350px;
    }
    
    .slider2-grid{
    height: 350px;

    }

}

@media only screen and (max-width: 549px) {
  .slider2-grid,.slider1-grid,.slider4-grid,.slider4-grid{
   
    width: 100%;
      } 
}





.reader-view-content{
  position: absolute;
  left: -1000px;
  top: -1000px;

}

.playstore-div{
  position:absolute;
  bottom: 10%;
}

.left-content-div{
  position: absolute;
    bottom: 30%;
}

@media only screen and (max-width: 570px) {
  p{
    font-size: 15px !important;
    }
    

}

@media only screen and (max-width: 500px) {
  .slider4-grid, .slider1-grid{
    height: 300px !important;
  }

p{
font-size: 13px !important;
}

.app-padding {
  padding-right: 10px !important;
  padding-left: 10px !important;

}

}

@media only screen and (max-width: 352px) {
  p{
    font-size: 11px !important;
    }

}


.slick-slide img{
  display: unset;
}

.slick-slide{
  margin-left: 1px;
}